.body--kontakt {
    background-color: #0B0B0B;
    color: #fff;

    .main {
        padding-top: 20px;
        min-height: 100vh;
    }
}


.kontakt {
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;

    display: grid;
    column-gap: 16px; 
    padding: 0 20px;
    width: 100%;

    &__kontakt {
    
        & p {
            margin-bottom: 100px;
    
            &:last-child {
                margin-bottom: 0px;
            }
            
        }

        a {
            color: #fff;
            text-decoration: underline;
        }

        .ck-editor {

            &__kleintext {
                font-size: 10px;
                line-height: 14px;
            }
        }
    }

    &__impressum {
        font-size: 10px;
        line-height: 13px;
    }
}

@include mediaquery(smartphone) {

    $layout: smartphone;

    .body--kontakt {
    
        .main {
            padding-top: groesse($layout, 260 - 20);
        }
    }

    .kontakt {
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: max-content;
        column-gap: 11px;

        &__kontakt {
            grid-column-start: 1;
            grid-column-end: 4;
            @include abstand($layout, margin, bottom, 100);
        }

        &__bild {
            grid-column-start: 1;
            grid-column-end: 5;
            margin: 0 groesse($layout, -22);
        }
    }
}

@include mediaquery(tabletHoch) {

    $layout: tabletHoch;

    .kontakt {
        grid-template-columns: repeat(6, 1fr);
        grid-auto-rows: max-content;

        &__kontakt {
            grid-column-start: 2;
            grid-column-end: 6;
            @include abstand($layout, margin, bottom, 100);
        }

        &__bild {
            grid-column: 2/6;
        }
    }
}

@include mediaquery(tabletQuer) {

    $layout: tabletQuer;

    .kontakt {
        grid-template-columns: repeat(9, 1fr);
        grid-auto-rows: max-content;

        &__kontakt {
            grid-column-start: 2;
            grid-column-end: 5;
        }
        
        &__bild {
            grid-column: 5/9;
            
            .macro-bild__img, .macro-bild__picture {
                position: relative; 

            }
        }

        &__bild &__bild-bild {
            padding-bottom: 0 !important
        }
        
        &__bild-img {
            object-fit: cover;
            object-position: center center;
        }
    }
}

@include mediaquery(desktop) {
    
    $layout: desktop;
    
    .kontakt {
        grid-template-columns: repeat(24, 1fr);
        grid-auto-rows: max-content;
        
        &__kontakt {
            grid-column-start: 2;
            grid-column-end: 5;
        }

        &__bild {
            grid-column: 5/11;

            .macro-bild__img, .macro-bild__picture {
                position: relative; 

            }
        }

        &__bild &__bild-bild {
            padding-bottom: 0 !important
        }
        
        &__bild-img {
            object-fit: cover;
            object-position: center center;
        }
    }
}

@include mediaquery(gross) {

    $layout: gross;

    .kontakt {
        grid-template-columns: repeat(36, 1fr);
        grid-auto-rows: max-content;

        &__kontakt {
            grid-column-start: 2;
            grid-column-end: 5;
        }

        &__bild {
            grid-column: 5/17;
            .macro-bild__img, .macro-bild__picture {
                position: relative; 

            }
        }

        &__bild &__bild-bild {
            padding-bottom: 0 !important
        }
        
        &__bild-img {
            object-fit: cover;
            object-position: center center;
        }
    }
}